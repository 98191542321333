import React, { useState, useRef, useEffect } from 'react';
import { Box, Checkbox, Divider, Flex, FormControl, FormLabel, Heading, Image, Select, Stack, Switch, Text, Button, Input, useToast } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { SketchPicker } from 'react-color';
import MockControls from "apps/Weblang/organisms/MockControls";
import { css } from '@emotion/react';
import axiosInstance from 'utils/axiosInstance';

const CustomiseControls = () => {
  const toast = useToast();
  const [selectedOption, setSelectedOption] = useState('fixed');
  const [colorSettings, setColorSettings] = useState({
    language_toggle_background_color: '#000000',
    language_toggle_text_color: '#FFFFFF',
    language_panel_background_color: '#FFFFFF',
    language_panel_text_color: '#162a41',
    show_flags: true,
  });
  const [colorPickerVisible, setColorPickerVisible] = useState(null);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [editorLink, setEditorLink] = useState(null); // State to hold the editor link
  const colorPickerRef = useRef(null);

  // Fetch initial settings from API
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/widget_configs');
        const { config } = response.data;

        setColorSettings({
          language_toggle_background_color: config.language_toggle_background_color,
          language_toggle_text_color: config.language_toggle_text_color,
          language_panel_background_color: config.language_panel_background_color,
          language_panel_text_color: config.language_panel_text_color,
          show_flags: config.show_flags,
          alphabetic_order: config.alphabetic_order,
          fixed_controls_mobile_only: config.fixed_controls_mobile_only,
        });

        setSelectedOption(config.show_language_selector ? 'fixed' : 'draggable');
      } catch (error) {
        console.error("Failed to load settings:", error);
      }
    };

    const fetchEditorLink = async () => {
      try {
        const linkResponse = await axiosInstance.get('/weblang/api/installations/link');
        setEditorLink(linkResponse.data.editor_link);
      } catch (error) {
        console.error("Failed to load editor link:", error);
      }
    };

    fetchSettings();
    fetchEditorLink();
  }, []);

  // Handle outside click to close color picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setColorPickerVisible(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionChange = async (option) => {
    setSelectedOption(option);
    try {
      await axiosInstance.put('/weblang/api/widget_configs', { config: { show_language_selector: option === 'fixed' } });
      if (!toast.isActive("optionUpdate")) {
        toast({
          id: "optionUpdate",
          title: "Option updated",
          description: `${option === 'fixed' ? 'Fixed language controls are shown on your site now' : 'Fixed language controls are hidden'}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
          variant: "solid",
          colorScheme: "blue",
        });
      }
    } catch (error) {
      console.error("Failed to update option:", error);
    }
  };

  const handleColorChange = (color, key) => {
    setColorSettings((prevSettings) => ({
      ...prevSettings,
      [key]: color.hex,
    }));
  };

  const handleSaveSettings = async () => {
    try {
      await axiosInstance.put('/weblang/api/widget_configs', { config: { ...colorSettings, show_language_selector: selectedOption === 'fixed' } });
      if (!toast.isActive("saveSuccess")) {
        toast({
          id: "saveSuccess",
          title: "Settings saved",
          description: "Your settings have been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
          variant: "solid",
          colorScheme: "blue",
        });
      }
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  const handleColorInputClick = (key) => {
    setColorPickerVisible(colorPickerVisible === key ? null : key);

    if (key.startsWith("language_panel")) {
      setControlsVisible(true);
    }
  };

  return (
    <Box maxW="7xl" mx="auto" pb={6} px={6}>
      <Heading mb={6}>Settings</Heading>

      <Box bg="white" p={6} rounded="md" shadow="sm">
        <Flex align="top" mb={6}>
          {/* Left Side: Fixed Language Controls */}
          <Box
            flex="1"
            opacity={selectedOption === 'fixed' ? 1 : 0.2}
            transition="opacity 0.3s ease"
            _hover={{ opacity: selectedOption === 'fixed' ? 1 : 0.4 }}
            cursor="pointer"
            onClick={() => selectedOption !== 'fixed' && handleOptionChange('fixed')}
          >
            <Stack spacing={4}>
              <Checkbox
                isChecked={selectedOption === 'fixed'}
                onChange={() => handleOptionChange('fixed')}
              >
                Fixed language controls (Recommended)
              </Checkbox>

              <Box position="relative" overflow="hidden" width="100%" maxW="800px">
                <Image src="/weblang/website_mock.svg" alt="Fixed language controls preview" rounded="md" boxShadow="sm" width="100%" draggable={false} />

                {/* Sliding MockControls */}
                <Box
                  position="absolute"
                  top="20%"
                  right={controlsVisible ? "5px" : "-110px"}
                  width="125px"
                  transition="right 0.3s ease-in-out"
                  onClick={() => setControlsVisible(!controlsVisible)}
                >
                  <MockControls colors={colorSettings} />
                </Box>
              </Box>

              {/* Color Pickers */}
              {[
                { key: 'language_toggle_background_color', label: 'Language Toggle Background Color' },
                { key: 'language_toggle_text_color', label: 'Language Toggle Text Color' },
                { key: 'language_panel_background_color', label: 'Language Panel Background Color' },
                { key: 'language_panel_text_color', label: 'Language Panel Text Color' },
              ].map(({ key, label }) => (
                <FormControl key={key} position="relative">
                  <FormLabel htmlFor={`${key}-color-input`} id={`${key}-label`}>
                    {label}
                  </FormLabel>
                  <Flex align="center">
                    <Box
                      width="24px"
                      height="24px"
                      bg={colorSettings[key]}
                      borderRadius="full"
                      border="1px solid #ccc"
                      cursor="pointer"
                      mr={2}
                      onClick={() => handleColorInputClick(key)}
                    />
                    <Input
                      id={`${key}-color-input`}
                      value={colorSettings[key]}
                      onClick={(e) => handleColorInputClick(key)}
                      onChange={(e) => handleColorChange({ hex: e.target.value }, key)}
                      css={css`
                        cursor: pointer;
                      `}
                    />
                  </Flex>
                  {colorPickerVisible === key && (
                    <Box position="absolute" zIndex="popover" mt={2} ref={colorPickerRef}>
                      <SketchPicker
                        color={colorSettings[key]}
                        onChange={(color) => handleColorChange(color, key)}
                        onClose={() => setColorPickerVisible(null)}
                      />
                    </Box>
                  )}
                </FormControl>
              ))}

              {/* Alphabetic Sorting Toggle */}
              <FormControl>
                <FormLabel htmlFor="alphabetic-order">Alphabetic Order</FormLabel>
                <Select
                    id="alphabetic-order"
                    value={colorSettings.alphabetic_order || ''}
                    onChange={(e) =>
                        setColorSettings((prevSettings) => ({
                          ...prevSettings,
                          alphabetic_order: e.target.value || null, // Set to null if empty
                        }))
                    }
                >
                  <option value="">Off</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </Select>
              </FormControl>

              {/* Show Flags Toggle */}
              <FormControl display="flex" alignItems="center">
                <Switch
                  isChecked={colorSettings.show_flags}
                  onChange={() =>
                    setColorSettings((prevSettings) => ({
                      ...prevSettings,
                      show_flags: !prevSettings.show_flags,
                    }))
                  }
                />
                <FormLabel mb="0" ml="2" htmlFor="showFlags-switch">
                  Show Flags
                </FormLabel>
              </FormControl>

              {/* Show on Mobile Only Toggle */}
              <FormControl display="flex" alignItems="center">
                <Switch
                  isChecked={colorSettings.fixed_controls_mobile_only}
                  onChange={() =>
                    setColorSettings((prevSettings) => ({
                      ...prevSettings,
                      fixed_controls_mobile_only: !prevSettings.fixed_controls_mobile_only,
                    }))
                  }
                />
                <FormLabel mb="0" ml="2" htmlFor="showFlags-switch">
                  Show on Mobile Only
                </FormLabel>
              </FormControl>

              <Button colorScheme="blue" mt={4} onClick={handleSaveSettings}>Save</Button>
            </Stack>
          </Box>

          {/* Center Divider with OR */}
          <Flex direction="column" align="center" mx={4}>
            <Divider orientation="vertical" borderColor="gray.300" borderStyle="dashed" />
            <Text my={2} color="gray.500">OR</Text>
            <Divider orientation="vertical" borderColor="gray.300" borderStyle="dashed" />
          </Flex>

          {/* Right Side: Draggable Wix Controls */}
          <Box
            flex="1"
            opacity={selectedOption === 'draggable' ? 1 : 0.2}
            transition="opacity 0.3s ease"
            _hover={{ opacity: selectedOption === 'draggable' ? 1 : 0.4 }}
            cursor="pointer"
            onClick={() => selectedOption !== 'draggable' && handleOptionChange('draggable')}
          >
            <Stack spacing={4}>
              <Checkbox
                isChecked={selectedOption === 'draggable'}
                onChange={() => handleOptionChange('draggable')}
              >
                Draggable Wix Controls
              </Checkbox>

              <Image src="/weblang/draggable_wix_controls.svg" alt="Draggable Wix controls preview" rounded="md" boxShadow="sm" draggable={false} />

              {/* Button to open Wix Editor */}
              <Button
                colorScheme="blue"
                variant="solid"
                rightIcon={<ExternalLinkIcon />}
                as="a"
                href={editorLink}
                target="_blank"
                disabled={!editorLink} // Disable button until link loads
              >
                Go to Wix Editor
              </Button>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default CustomiseControls;
