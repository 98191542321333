import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Flex,
  Text,
  Spinner,
} from '@chakra-ui/react';

const PaginatedTable = ({
                          columns,
                          data,
                          isLoading,
                          currentPage,
                          totalPages,
                          onPageChange,
                        }) => {
  return (
    <Box>
      {/* Table */}
      <Table variant="simple">
        <Thead>
          <Tr>
            {columns.map((col) => (
              <Th key={col.header}>{col.header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={columns.length} textAlign="center">
                <Spinner size="lg" />
                <Text mt="2">Loading...</Text>
              </Td>
            </Tr>
          ) : data.length > 0 ? (
            data.map((row) => (
              <Tr key={row.id}>
                {columns.map((col) => (
                  <Td key={col.key}>
                    {col.render ? col.render(row) : row[col.key]}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length} textAlign="center">
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/* Pagination */}
      <Flex justify="space-between" align="center" mt="4">
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <Flex>
          <Button
            size="sm"
            onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
            isDisabled={currentPage === 1}
            mr="2"
          >
            Previous
          </Button>
          <Button
            size="sm"
            onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
            isDisabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PaginatedTable;
