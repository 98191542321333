import React from 'react';
import {
  Alert,
  AlertIcon,
  Flex,
  Text,
  Button,
  Box,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';

const NoticeAlert = ({ label, description, buttonText, onClick, isLoading }) => {
  return (
    <Alert
      status="info"
      borderRadius="md"
      boxShadow="sm"
      bg={useColorModeValue('blue.50', 'blue.900')}
      color={useColorModeValue('blue.800', 'blue.200')}
      px="6"
      py="4"
    >
      <Flex align="center" justify="space-between" w="full">
        {/* Left Section: Icon and Text */}
        <Flex align="center">
          <AlertIcon
            color={useColorModeValue('blue.600', 'blue.400')}
            mr="4"
            boxSize="4" // Slightly larger icon for balance
          />
          <Box>
            <Text fontWeight="bold">{label}</Text>
            {isLoading ? (
              <Flex align="center" mt="1">
                <Spinner size="sm" mr="2" />
                <Text fontSize="sm">Loading...</Text>
              </Flex>
            ) : (
              <Text fontSize="sm">{description}</Text>
            )}
          </Box>
        </Flex>

        {/* Right Section: Action Button */}
        <Button
          size="sm"
          colorScheme="blue"
          onClick={onClick}
          isLoading={isLoading}
          loadingText="Loading"
        >
          {buttonText}
        </Button>
      </Flex>
    </Alert>
  );
};

export default NoticeAlert;
