import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

const ProductCard = ({ name, sku, price, image }) => {
  return (
    <Flex align="center" w="full">
      <Image
        src={image || '/etsy_easy_sync/placeholder.png'}
        alt={name}
        boxSize="50px"
        objectFit="cover"
        borderRadius="md"
        mr="3"
      />
      <Box>
        <Text fontWeight="bold">{name}</Text>
        <Text fontSize="sm" color="gray.500">
          { sku ? `SKU ${sku}  -` : null } {price}
        </Text>
      </Box>
    </Flex>
  );
};

export default ProductCard;
