import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  useColorModeValue,
  Text,
  Link as ChakraLink,
  Spinner,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import axiosInstance from 'utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

// Shake Animation Wrapper for the form
const ShakeBox = ({ children, shouldShake }) => {
  return (
    <motion.div
      animate={shouldShake ? { x: [0, -10, 10, -10, 0] } : {}}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

const Settings = () => {
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const boxBg = useColorModeValue('white', 'gray.700');
  const headingColor = useColorModeValue('gray.800', 'white');

  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [globalError, setGlobalError] = useState(null);
  const [shakeOnValidationFail, setShakeOnValidationFail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await axiosInstance.get('/etsy_easy_sync/api/settings/edit');
        setSettings(data);
      } catch (e) {
        setGlobalError('Failed to load settings.');
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const handleChange = (key, value) => {
    setSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value: value === '' ? null : value,
      },
    }));
    setErrors((prev) => ({
      ...prev,
      [key]: null,
    }));
    setShakeOnValidationFail(false); // Reset shake on change
  };

  const validateForm = () => {
    const newErrors = {};
    Object.entries(settings).forEach(([key, setting]) => {
      const { required, value, allowed_values } = setting;
      if (
        required &&
        ((allowed_values && allowed_values.length > 0 && !value) ||
          (key === 'etsy_shipping_profile_id' && allowed_values.length === 0))
      ) {
        newErrors[key] = 'This field is required.';
      }
    });

    setErrors(newErrors);
    setShakeOnValidationFail(Object.keys(newErrors).length > 0); // Trigger shake only on failure
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setGlobalError(null);

    if (!validateForm()) return;

    const payload = {};
    for (const [key, setting] of Object.entries(settings)) {
      payload[key] = setting.value;
    }

    try {
      await axiosInstance.patch('/etsy_easy_sync/api/settings', { settings: payload });
      console.log('Settings saved successfully!');
      navigate('/etsy_easy_sync/product_mappings');
    } catch (error) {
      setGlobalError('Failed to save settings. Please try again.');
    }
  };

  const renderSettingField = (key, setting) => {
    const { displayed_name, value, required, allowed_values } = setting;
    const fieldError = errors[key];

    if (required && allowed_values && allowed_values.length === 0 && key === 'etsy_shipping_profile_id') {
      return (
        <FormControl mb="4" isInvalid={!!fieldError} key={key}>
          <FormLabel>{displayed_name}</FormLabel>
          <Text color="red.500">
            No shipping profiles found. Please create one on{' '}
            <ChakraLink
              color="blue.500"
              href="https://www.etsy.com/your/shops/me/tools/shipping-profiles"
              isExternal
            >
              Etsy
            </ChakraLink>{' '}
            and then{' '}
            <ChakraLink color="blue.500" onClick={() => window.location.reload()}>
              refresh this page
            </ChakraLink>.
          </Text>
        </FormControl>
      );
    }

    return (
      <FormControl mb="4" isInvalid={!!fieldError} key={key}>
        <FormLabel>
          {displayed_name} {required && <Text as="span" color="red.500">*</Text>}
        </FormLabel>
        <Select
          placeholder="Select option"
          value={value || ''}
          onChange={(e) => handleChange(key, e.target.value)}
        >
          {allowed_values &&
            allowed_values.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.displayed_value}
              </option>
            ))}
        </Select>
        {fieldError && <Text color="red.500">{fieldError}</Text>}
      </FormControl>
    );
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" w="full" h="100vh">
        <Spinner size="xl" color="blue.500" />
      </Flex>
    );
  }

  if (globalError) {
    return (
      <Box color="red.600" fontWeight="bold" p="4" textAlign="center">
        {globalError}
      </Box>
    );
  }

  return (
    <Flex align="center" justify="center" w="full" px="6" bg={bgColor}>
      <ShakeBox w="full" shouldShake={shakeOnValidationFail}>
        <Box bg={boxBg} borderRadius="lg" boxShadow="lg" w="xl" p="8">
          <Heading as="h1" size="lg" color={headingColor} mb="6" textAlign="center">
            Answer a few questions
          </Heading>

          <form onSubmit={handleNext}>
            {Object.entries(settings).map(([key, setting]) => renderSettingField(key, setting))}

            <Button colorScheme="blue" size="lg" w="full" mt="6" type="submit">
              Next
            </Button>
          </form>
        </Box>
      </ShakeBox>
    </Flex>
  );
};

export default Settings;
