import React from 'react';
import { Box, Heading, Text, Button, useColorModeValue, Spinner } from '@chakra-ui/react';
import { IconWrapper } from '../atoms/IconWrapper';

interface ActionCardProps {
  icon: React.ElementType; // Icon component
  title: string; // Header text
  description: string; // Paragraph text
  buttonText: string; // Button text
  onButtonClick?: () => void; // Button action handler
  loading?: boolean; // Loading state
  buttonColor?: string;
}

export const ActionCard: React.FC<ActionCardProps> = ({
                                                        icon,
                                                        title,
                                                        description,
                                                        buttonText,
                                                        onButtonClick,
                                                        loading = false,
                                                        buttonColor = 'blue',
                                                      }) => {
  const bg = useColorModeValue('white', 'gray.700');
  const boxShadow = useColorModeValue('md', 'lg');

  return (
    <Box
      bg={bg}
      borderRadius="lg"
      boxShadow={boxShadow}
      maxW="lg"
      w="full"
      p="8"
      textAlign="center"
    >
      <IconWrapper icon={icon} />

      <Heading as="h1" size="lg" color={useColorModeValue('gray.800', 'white')} mb="4">
        {title}
      </Heading>

      <Text fontSize="md" color={useColorModeValue('gray.600', 'gray.300')} mb="6">
        {description}
      </Text>

      <Button
        colorScheme={buttonColor}
        size="md"
        w="full"
        onClick={onButtonClick}
        isDisabled={loading} // Disable button while loading
      >
        {loading ? <Spinner size="sm" /> : buttonText}
      </Button>
    </Box>
  );
};
