import React from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

const CreateProductCard = ({ onClick, hoverBg = 'gray.100', vendor= 'wix' }) => {
  return (
    <Flex
      align="center"
      w="full"
      p="3"
      cursor="pointer"
      _hover={{ bg: hoverBg }}
      onClick={onClick}
    >
      <Box width="50px">
        <Icon as={AddIcon} boxSize="6" mr="3" color="gray.500" />
      </Box>
      <Box>
        <Text fontWeight="bold">{ `Import from ${vendor === 'wix' ? 'Etsy' : 'Wix'}` }</Text>
      </Box>
    </Flex>
  );
};

export default CreateProductCard;
