import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Spinner, useColorModeValue as mode } from '@chakra-ui/react';
import { Header } from 'shared/organisms/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import axiosInstance from '../utils/axiosInstance';
import weblangFlowControlMiddleware from "../middleware/weblangFlowControlMiddleware";

const WeblangLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);
  const [unlimited, setUnlimited] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [visitorData, setVisitorData] = useState(null); // Store visitor data
  const [loadingVisitorData, setLoadingVisitorData] = useState(true); // Track visitor data loading
  const tawkMessengerRef = useRef();

  // Pages that are part of the install flow where we don't want to redirect
  const installFlowPages = ['/weblang/install', '/weblang/payment', '/weblang/editor_install'];
  const informationalPages = ['/weblang/pricing_info'];

  useEffect(() => {
    weblangFlowControlMiddleware(location.pathname, navigate);
  }, [location.pathname, navigate]);

  // Fetch balance from the payments API
  useEffect(() => {
    const fetchBalance = async () => {
      if (informationalPages.includes(location.pathname)) { return; }

      try {
        const response = await axiosInstance.get('/weblang/api/payments/balance');
        setBalance(response.data.balance);
        setUnlimited(response.data.unlimited);
        setLoadingBalance(false);

        // Redirect to the install page if the balance is null and we're not on an install flow page
        if (response.data.balance === null && !installFlowPages.includes(location.pathname)) {
          navigate('/weblang/install');
        }
      } catch (error) {
        console.error('Failed to fetch balance:', error);
        setLoadingBalance(false);

        // If there's an error, redirect to the install page if we're not on an install flow page
        if (!installFlowPages.includes(location.pathname)) {
          navigate('/weblang/install');
        }
      }
    };
    fetchBalance();
  }, [navigate, location.pathname]);

  // Fetch visitor data for TawkMessenger
  useEffect(() => {
    const fetchVisitorData = async () => {
      try {
        const response = await axiosInstance.get('/support_chat/api/configurations');
        setVisitorData(response.data);
      } catch (error) {
        console.error('Failed to fetch visitor data:', error);
      } finally {
        setLoadingVisitorData(false);
      }
    };
    fetchVisitorData();
  }, []);

  // Set attributes and tags using TawkMessenger
  const handleMessengerLoad = () => {
    if (visitorData && tawkMessengerRef.current) {
      // Set name and email as attributes
      tawkMessengerRef.current.setAttributes(
        { name: visitorData.username, email: visitorData.email },
        (error) => {
          if (error) {
            console.error('Failed to set attributes on TawkMessenger:', error);
          }
        }
      );

      // Add tags for wix_id and app_name
      tawkMessengerRef.current.addTags(
        [visitorData.wix_id, visitorData.app_name],
        (error) => {
          if (error) {
            console.error('Failed to add tags on TawkMessenger:', error);
          }
        }
      );
    }
  };

  // Links for the header navigation
  const links = [
    { label: 'Languages', href: '/weblang/languages' },
    { label: 'Customise Controls', href: '/weblang/customise_controls' },
    { label: 'Billing', href: '/weblang/payment' },
    { label: 'Help', href: '/weblang/help' },
  ];

  // Determine if we are on the /weblang/install page
  const hideLinksAndBalance = ['/weblang/install', '/weblang/pricing_info'].includes(location.pathname);

  return (
    <Box>
      <Header
        homeHref="/weblang/languages"
        logo="/weblang_logo.png"
        links={hideLinksAndBalance ? [] : links} // Hide links on /weblang/install
        activeLink={location.pathname}
        actionsComponent={
          !hideLinksAndBalance &&
          (loadingBalance ? (
            <Spinner size="md" />
          ) : (
            <Button colorScheme="blue" rounded="full">
              Balance: { `${unlimited ? '∞' : balance?.toFixed(0)} words` }
            </Button>
          ))
        }
      />
      <Box as="main" py="6" px="4" bg={mode('gray.100', 'gray.700')} minHeight="calc(100vh - 65px)">
        <Outlet />
      </Box>

      {/* Render widget after visitor data is fetched */}
      {process.env.REACT_APP_TAWK_TO_PROPERTY_ID && !loadingVisitorData && (
        <div className="App">
          <TawkMessengerReact
            propertyId={process.env.REACT_APP_TAWK_TO_PROPERTY_ID}
            widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
            ref={tawkMessengerRef}
            onLoad={handleMessengerLoad} // Handle loading attributes and tags
          />
        </div>
      )}
    </Box>
  );
};

export default WeblangLayout;
