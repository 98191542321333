import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Middlewares and Layouts
import instanceMiddleware from './middleware/instanceMiddleware';
import datadogMiddleware from './middleware/datadogMiddleware';
import WeblangLayout from './layouts/WeblangLayout';
import EtsyEasySyncLayout from "layouts/EtsyEasySyncLayout";

// Weblang
import WeblangHelp from 'apps/Weblang/pages/Help';
import WeblangPricingInfo from 'apps/Weblang/pages/PricingInfo';
import WeblangInstall from 'apps/Weblang/pages/Install';
import WeblangPayment from 'apps/Weblang/pages/Payment';
import EditorInstall from 'apps/Weblang/pages/EditorInstall';
import WeblangLanguages from 'apps/Weblang/pages/Languages';
import WeblangCreateLanguages from 'apps/Weblang/pages/CreateLanguages';
import WeblangEditTranslation from 'apps/Weblang/pages/EditTranslation';
import WeblangCustomiseControls from 'apps/Weblang/pages/CustomiseControls';
import WeblangMultilingualIndex from 'apps/WeblangMultilingual/pages/Index';

// Etsy
import EtsyEasySyncPayment from 'apps/EtsyEasySync/pages/Payment';
import EtsyEasySyncConnectEtsy from 'apps/EtsyEasySync/pages/ConnectEtsy';
import EtsyEasySyncFinalizeEtsyConnection from 'apps/EtsyEasySync/pages/FinalizeEtsyConnection';
import EtsyEasySyncSettings from 'apps/EtsyEasySync/pages/Settings';
import EtsyEasySyncProductMappings from 'apps/EtsyEasySync/pages/ProductMappings';
import EtsyEasySyncSynchronizationHistory from 'apps/EtsyEasySync/pages/SynchronizationHistory';
import EtsyEasySyncSynchronizationRun from 'apps/EtsyEasySync/pages/SynchronizationRun';

const App = () => {
  useEffect(() => {
    datadogMiddleware();
    instanceMiddleware();
  }, []);

  return (
    <Router>
      <Routes>
        {/* Parent route with WeblangLayout that contains child routes */}
        <Route element={<WeblangLayout />}>
          {/* All Weblang pages */}
          <Route path="/weblang/pricing_info" element={<WeblangPricingInfo />} />
          <Route path="/weblang/install" element={<WeblangInstall />} />
          <Route path="/weblang/payment" element={<WeblangPayment />} />
          <Route path="/weblang/editor_install" element={<EditorInstall />} />
          <Route path="/weblang/languages" element={<WeblangLanguages />} />
          <Route path="/weblang/languages/new" element={<WeblangCreateLanguages />} />
          <Route path="/weblang/:languageId/translations" element={<WeblangEditTranslation />} />
          <Route path="/weblang/customise_controls" element={<WeblangCustomiseControls />} />
          <Route path="/weblang/help" element={<WeblangHelp />} />
        </Route>

        <Route element={<EtsyEasySyncLayout />}>
          {/* All Weblang pages */}
          <Route path="/etsy_easy_sync/pricing_info" element={<WeblangPricingInfo />} />
          <Route path="/etsy_easy_sync/payment" element={<EtsyEasySyncPayment />} />
          <Route path="/etsy_easy_sync/connect_etsy" element={<EtsyEasySyncConnectEtsy />} />
          <Route path="/etsy_easy_sync/connect_etsy/finalize" element={<EtsyEasySyncFinalizeEtsyConnection />} />
          <Route path="/etsy_easy_sync/settings" element={<EtsyEasySyncSettings />} />
          <Route path="/etsy_easy_sync/product_mappings" element={<EtsyEasySyncProductMappings />} />
          <Route path="/etsy_easy_sync/synchronization_history" element={<EtsyEasySyncSynchronizationHistory />} />
          <Route path="/etsy_easy_sync/synchronization_history/:synchronizationId" element={<EtsyEasySyncSynchronizationRun />} />
        </Route>

        <Route path="/weblang_multilingual" element={<WeblangMultilingualIndex />} />
        <Route path="/" element={<div>Welcome to Rubyroid Tech UI</div>} />
      </Routes>
    </Router>
  );
};

export default App;
