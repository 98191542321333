import React, { useState } from 'react';
import {
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  Alert,
  AlertIcon,
  Button,
} from '@chakra-ui/react';
import { DurationSwitch } from '../molecules/DurationSwitch';
import { PricingCard } from './PricingCard';

interface PricingBlockProps {
  pricingPlans: Array<{
    name: string;
    description: string;
    monthly: number;
    yearly: number;
    one_time: number;
    features: string[];
    locked?: boolean;
    first_month_price?: number;
    one_time_payment_url?: string;
    monthly_payment_url?: string;
    yearly_payment_url?: string;
  }> | null;
  loading: boolean;
  error: string | null;
  informationalMode?: boolean;
}

const PricingBlock: React.FC<PricingBlockProps> = ({
                                                     pricingPlans,
                                                     loading,
                                                     error,
                                                     informationalMode = false,
                                                   }) => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>(
    'monthly'
  );

  if (loading) {
    return (
      <Box textAlign="center" py="6">
        <Spinner size="xl" />
        <Text mt="4">Loading Pricing Plans...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py="6">
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box as="section" py="10">
      <Box maxW="7xl" mx="auto">
        {/* Billing Cycle Switch */}
        <Flex justify="center" mb="6">
          <DurationSwitch value={billingCycle} onChange={setBillingCycle} />
        </Flex>

        {/* Pricing Cards */}
        <Flex
          wrap="wrap"
          justify="center"
          align="center"
          gap="10"
          w="full"
          mx="auto"
        >
          {pricingPlans && pricingPlans.length > 0 ? (
            pricingPlans.map((plan, index) => (
              <PricingCard
                key={index}
                bg={['gray.50', 'blue.600', 'gray.700'][index % 3]}
                color={index === 0 ? 'black' : 'white'}
                button={
                  <Button
                    w="full"
                    h="16"
                    colorScheme={index === 0 ? 'blue' : 'gray'}
                    variant={index === 0 ? 'outline' : 'solid'}
                    size="lg"
                    isDisabled={
                      informationalMode || (
                        !plan.one_time_payment_url &&
                        !plan.monthly_payment_url &&
                        !plan.yearly_payment_url
                      )
                    }
                    onClick={() => {
                      const url = plan.one_time_payment_url || {
                        monthly: plan.monthly_payment_url,
                        yearly: plan.yearly_payment_url
                      }[billingCycle]

                      if (url) { window.location.href = url; }
                    }}
                  >
                    {plan.name === 'Free' ? 'Start for Free' : 'Select'}
                  </Button>
                }
                data={{
                  ...plan,
                  selectedDuration: billingCycle,
                }}
              />
            ))
          ) : (
            <Box textAlign="center" py="10">
              No pricing plans available.
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default PricingBlock;
