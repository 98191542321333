import {format, parseISO} from "date-fns";

const formatToLocalTime = (utcTime) => {
  if (!utcTime) return 'N/A';

  const date = parseISO(utcTime);
  const formattedTime = format(date, "MMM dd, yyyy HH:MM");

  // Retrieve user's timezone name (e.g., GMT+2, EST)
  const timeZone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
    .formatToParts(date)
    .find((part) => part.type === 'timeZoneName')?.value;

  return `${formattedTime} ${timeZone || ''}`;
};

export default formatToLocalTime;
