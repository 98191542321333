import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Alert,
  AlertIcon,
  Spinner,
  Text,
  useColorModeValue as mode,
  Flex,
  Heading,
  IconButton,
} from '@chakra-ui/react'
import { Header } from 'shared/organisms/Header'
import axiosInstance from 'utils/axiosInstance'
import { useNavigate } from 'react-router-dom'
import { HiArrowLeft } from 'react-icons/hi'

const CreateLanguage = () => {
  const [availableLanguages, setAvailableLanguages] = useState([]) // Store available languages
  const [selectedLanguage, setSelectedLanguage] = useState('') // Track selected language code
  const [loading, setLoading] = useState(true) // Handle loading state for fetching languages
  const [error, setError] = useState(null) // Handle errors
  const [alertMessage, setAlertMessage] = useState('') // Handle alert messages
  const [alertStatus, setAlertStatus] = useState('success') // To manage success or error alerts
  const navigate = useNavigate()

  // Fetch available languages from the API
  useEffect(() => {
    const fetchAvailableLanguages = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/supported_languages?type=target')
        setAvailableLanguages(response.data)
        setLoading(false)
      } catch (error) {
        setError('Failed to load available languages.')
        setLoading(false)
      }
    }
    fetchAvailableLanguages()
  }, [])

  // Handle form submission for creating a new language
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!selectedLanguage) {
      setAlertMessage('Please select a language.')
      setAlertStatus('error')
      return
    }

    try {
      // Make POST request to create a new language
      const response = await axiosInstance.post('/weblang/api/languages', {
        code: selectedLanguage,
      })
      setAlertMessage('Language created successfully.')
      setAlertStatus('success')
      // Redirect to the languages list on success
      navigate('/weblang/languages')
    } catch (error) {
      handleErrorResponse(error)
    }
  }

  // Handle error response for validation or server errors
  const handleErrorResponse = (error) => {
    const responseErrors = error.response?.data?.errors
    if (responseErrors?.code?.[0]?.includes('Invalid language code')) {
      setAlertMessage('Invalid language code.')
      setAlertStatus('error')
    } else if (responseErrors) {
      const formattedErrors = Object.keys(responseErrors)
        .map((field) => `${field.replace('_', ' ')}: ${responseErrors[field].join(', ')}`)
        .join('. ')
      setAlertMessage(`Validation error: ${formattedErrors}`)
      setAlertStatus('error')
    } else {
      setAlertMessage('An unexpected error occurred. Please try again.')
      setAlertStatus('error')
    }
  }

  return (
    <Box bg={mode('gray.100', 'gray.700')} minHeight="100vh">
      <Box as="section">
        <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
          {/* Header Row with Back Arrow and "New Language" Title */}
          <Flex justifyContent="space-between" alignItems="center" mb="6">
            <Flex align="center">
              <IconButton
                icon={<HiArrowLeft />}
                onClick={() => navigate('/weblang/languages')}
                aria-label="Back to languages"
                mr="2"
              />
              <Heading size="lg">Create New Language</Heading>
            </Flex>
          </Flex>

          {/* Show error or success alert */}
          {alertMessage && (
            <Alert status={alertStatus} mb="6">
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}

          {/* Show loading spinner while fetching languages */}
          {loading ? (
            <Box textAlign="center" py="10">
              <Spinner size="xl" />
              <Text mt="4">Loading available languages...</Text>
            </Box>
          ) : (
            <Box as="form" onSubmit={handleSubmit} bg="white" p="6" borderRadius="md">
              <FormControl isRequired>
                <FormLabel>Select Language Code</FormLabel>
                <Select
                  placeholder="Select language"
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                  {availableLanguages.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {/* Submit Button */}
              <Button
                type="submit"
                colorScheme="blue"
                mt="4"
                isFullWidth
              >
                Add Language
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CreateLanguage
