import React from 'react';
import { Flex, Icon, useColorModeValue } from '@chakra-ui/react';

interface IconWrapperProps {
  icon: React.ElementType;
}

export const IconWrapper: React.FC<IconWrapperProps> = ({ icon }) => {
  return (
    <Flex
      align="center"
      justify="center"
      w="16"
      h="16"
      bg={useColorModeValue('blue.50', 'blue.900')}
      borderRadius="full"
      mx="auto"
      mb="6"
    >
      <Icon as={icon} w="8" h="8" color={useColorModeValue('blue.600', 'blue.400')} />
    </Flex>
  );
};
