import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {Flex, Spinner, useColorModeValue} from '@chakra-ui/react';
import { ActionCard } from 'shared/organisms/ActionCard';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import axiosInstance from 'utils/axiosInstance';

const FinalizeEtsyConnection = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading'); // loading | success | error
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const processCallback = async () => {
      const error = searchParams.get('error');
      const errorDescription = searchParams.get('error_description');
      const code = searchParams.get('code');

      if (error) {
        setErrorMessage(errorDescription || 'An unknown error occurred.');
        setStatus('error');
        return;
      }

      if (code) {
        try {
          // Send the code to finalize OAuth
          const response = await axiosInstance.post('/etsy_easy_sync/api/etsy_oauth/finalize', { code: code });
          if (response.data?.success === true) {
            setStatus('success');
          } else {
            throw new Error('Failed to finalize Etsy connection.');
          }
        } catch (err) {
          setErrorMessage('Failed to finalize Etsy connection. Please try again.');
          setStatus('error');
        }
      } else {
        setErrorMessage('Invalid OAuth response received.');
        setStatus('error');
      }
    };

    processCallback();
  }, [searchParams]);

  const handleRetry = () => {
    navigate('/etsy_easy_sync/connect_etsy');
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="full"
      px="6"
    >
      {status === 'loading' && (
        <ActionCard
          icon={Spinner}
          title="Processing..."
          description="We are finalizing your Etsy connection."
          buttonText="Please Wait"
          buttonColor="gray"
        />
      )}

      {status === 'success' && (
        <ActionCard
          icon={CheckCircleIcon}
          title="Etsy Account Connected!"
          description="Your Etsy account has been successfully connected."
          buttonText="Continue"
          onButtonClick={() => navigate('/etsy_easy_sync/settings')}
          buttonColor="blue"
        />
      )}

      {status === 'error' && (
        <ActionCard
          icon={WarningIcon}
          title="Connection Failed"
          description={errorMessage}
          buttonText="Back"
          onButtonClick={handleRetry}
          buttonColor="gray"
        />
      )}
    </Flex>
  );
};

export default FinalizeEtsyConnection;
