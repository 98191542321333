import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import CreateProductCard from './CreateProductCard';
import ProductCard from './ProductCard';

const ProductSelector = ({
                           vendor,
                           currentProduct, // Current selected product
                           searchProducts, // Function to fetch products by search term
                           onSelect, // Callback when a product is selected
                         }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const menuRef = useRef(null);

  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.100', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const searchIconColor = useColorModeValue('gray.500', 'gray.400');

  // Trigger external searchProducts function when search term changes
  useEffect(() => {
    if (!isEditing || searchTerm.trim() === '') {
      setFilteredProducts([]);
      return;
    }

    const fetchProducts = async () => {
      setLoading(true);
      try {
        const results = await searchProducts(searchTerm);
        setFilteredProducts(results || []);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setFilteredProducts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [searchTerm, isEditing, searchProducts]);

  // Handle product selection
  const handleSelectProduct = (product) => {
    setIsEditing(false);
    if (onSelect) onSelect(product);
  };

  return (
    <Menu isOpen={isEditing} onClose={() => setIsEditing(false)}>
      <MenuButton
        as={Box}
        onClick={() => setIsEditing(true)}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        p="3"
        bg="white"
        _hover={{ boxShadow: 'lg' }}
        ref={menuRef}
      >
        <Flex align="center" justify="space-between">
          {currentProduct ? (
            <ProductCard
              name={currentProduct.name}
              sku={currentProduct.sku}
              price={currentProduct.price}
              image={currentProduct.image}
            />
          ) : (
            <CreateProductCard onClick={() => handleSelectProduct(null)} vendor={vendor} />
          )}
          <ChevronDownIcon fontSize="2xl" />
        </Flex>
      </MenuButton>

      <MenuList
        p="4"
        bg="white"
        maxW="400px"
        w={menuRef.current ? `${menuRef.current.offsetWidth}px` : 'auto'}
      >
        {/* Search Input */}
        <Flex align="center" mb="4">
          <SearchIcon color={searchIconColor} mr="2" />
          <Input
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="sm"
          />
        </Flex>

        {/* "Create New Product" Option */}
        <Box mb="2">
          <CreateProductCard
            vendor={vendor}
            onClick={() =>
              handleSelectProduct({ name: 'Create New Product', sku: '', price: '', image: '' })
            }
          />
        </Box>

        {/* Product Options */}
        {loading ? (
          <Box textAlign="center" py="4">
            <Spinner size="sm" />
            <Text mt="2" color={textColor}>
              Searching products...
            </Text>
          </Box>
        ) : filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Box
              key={product.etsy_id || product.wix_id}
              mb="2"
              onClick={() => handleSelectProduct(product)}
              cursor="pointer"
              _hover={{ bg: hoverBg }}
            >
              <ProductCard
                name={product.name}
                sku={product.sku}
                price={product.price}
                image={product.image}
              />
            </Box>
          ))
        ) : (
          <Text textAlign="center" color={textColor} py="2">
            No products found.
          </Text>
        )}
      </MenuList>
    </Menu>
  );
};

export default ProductSelector;
