import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  IconButton,
  Progress,
  useColorModeValue as mode,
  Alert,
  AlertIcon,
  Spinner,
  Flex,
  Heading,
  Text,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { HiPencilAlt, HiEyeOff, HiEye, HiTrash } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import axiosInstance from 'utils/axiosInstance'
import dayjs from 'dayjs'

const Languages = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTranslation, setSelectedTranslation] = useState(null)
  const [translations, setTranslations] = useState([])
  const [loading, setLoading] = useState(true)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertStatus, setAlertStatus] = useState('success')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/languages')
        const languages = response.data
        setTranslations(languages)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setAlertMessage('An unexpected error occurred. Please reach out for support at andrew@rubyroid.tech')
        setAlertStatus('error')
      }
    }
    fetchLanguages()
  }, [])

  const handleDelete = (translation) => {
    setSelectedTranslation(translation)
    onOpen()
  }

  const confirmDelete = async () => {
    try {
      await axiosInstance.delete(`/weblang/api/languages/${selectedTranslation.id}`)
      setTranslations(translations.filter(t => t.id !== selectedTranslation.id))
      setAlertMessage('Language deleted successfully.')
      setAlertStatus('success')
      onClose()
    } catch (error) {
      setAlertMessage('Failed to delete the language.')
      setAlertStatus('error')
    }
  }

  const handleDisable = async (translation) => {
    try {
      await axiosInstance.put(`/weblang/api/languages/${translation.id}`, { is_active: false })
      setTranslations(translations.map(t => t.id === translation.id ? { ...t, is_active: false } : t))
      setAlertMessage('Language disabled successfully.')
      setAlertStatus('success')
    } catch (error) {
      handleErrorResponse(error)
    }
  }

  const handleEnable = async (translation) => {
    try {
      await axiosInstance.put(`/weblang/api/languages/${translation.id}`, { is_active: true })
      setTranslations(translations.map(t => t.id === translation.id ? { ...t, is_active: true } : t))
      setAlertMessage('Language enabled successfully.')
      setAlertStatus('success')
    } catch (error) {
      handleErrorResponse(error)
    }
  }

  const handleEdit = (translation) => {
    navigate(`/weblang/${translation.id}/translations`)
  }

  const handleErrorResponse = (error) => {
    const responseErrors = error.response?.data?.errors
    if (responseErrors?.is_active?.[0]?.includes('cannot exceed the limit')) {
      setAlertMessage('You cannot exceed the active language limit for your current plan.')
      setAlertStatus('error')
    } else if (responseErrors) {
      const formattedErrors = Object.keys(responseErrors)
        .map(field => `${field.replace('_', ' ')}: ${responseErrors[field].join(', ')}`)
        .join('. ')
      setAlertMessage(`Validation error: ${formattedErrors}`)
      setAlertStatus('error')
    } else {
      setAlertMessage('An unexpected error occurred. Please try again.')
      setAlertStatus('error')
    }
  }

  return (
    <Box bg={mode('gray.100', 'gray.700')}>
      <Box as="section">
        <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
          <Flex justifyContent="space-between" alignItems="center" mb="6">
            <Heading size="lg">Languages</Heading>
            <Button colorScheme="blue" onClick={() => navigate('/weblang/languages/new')}>
              Add Language
            </Button>
          </Flex>

          {alertMessage && (
            <Alert status={alertStatus} mb="6">
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}

          {loading ? (
            <Box textAlign="center" py="10">
              <Spinner size="xl" />
              <Text mt="4">Loading Languages...</Text>
            </Box>
          ) : (
            <TableContainer bg="white" borderRadius="md">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Language</Th>
                    <Th>Words Count</Th>
                    <Th>Manually Reviewed Words</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {translations.map((translation) => (
                    <Tr
                      key={translation.id}
                      color={translation.is_active ? 'inherit' : mode('gray.500', 'gray.400')}
                    >
                      <Td>{translation.name}</Td>
                      <Td>{translation.words_count}</Td>
                      <Td>
                        <Flex align="center">
                          <Box width="70%" verticalAlign="middle">
                            <Progress
                              value={translation.reviewed_percentage || 0}
                              colorScheme="blue"
                              size="md"
                              borderRadius="md"
                            />
                          </Box>
                          <Box width="30%" verticalAlign="middle">
                            <Text ml={2} fontSize="sm" fontWeight="bold">
                              {translation.reviewed_percentage || 0}%
                            </Text>
                          </Box>
                        </Flex>
                      </Td>
                      <Td>
                        <Tooltip label="Edit" fontSize="md">
                          <IconButton
                            icon={<HiPencilAlt />}
                            aria-label="Edit Translation"
                            size="sm"
                            mr={2}
                            onClick={() => handleEdit(translation)}
                          />
                        </Tooltip>
                        {translation.is_active ? (
                          <Tooltip label="Disable" fontSize="md">
                            <IconButton
                              icon={<HiEyeOff />}
                              aria-label="Disable Translation"
                              size="sm"
                              mr={2}
                              color="gray.500"
                              opacity={0.5}
                              onClick={() => handleDisable(translation)}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip label="Enable" fontSize="md">
                            <IconButton
                              icon={<HiEye />}
                              aria-label="Enable Translation"
                              size="sm"
                              mr={2}
                              onClick={() => handleEnable(translation)}
                            />
                          </Tooltip>
                        )}
                        <Tooltip label="Delete" fontSize="md">
                          <IconButton
                            icon={<HiTrash />}
                            aria-label="Delete Translation"
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDelete(translation)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the translation for "{selectedTranslation?.name}"?
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Languages
