import React, {useState, useEffect, useCallback, useRef} from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Switch,
  useColorModeValue,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { RepeatIcon, DeleteIcon } from '@chakra-ui/icons';
import NoticeAlert from 'shared/molecules/NoticeAlert';
import PaginatedTable from 'shared/organisms/PaginatedTable';
import ProductSelector from '../molecules/ProductSelector';
import ProductCard from '../molecules/ProductCard';
import axiosInstance from 'utils/axiosInstance';
import formatToLocalTime from 'utils/timeTools';
import { useNavigate } from "react-router-dom";

const ProductMappings = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [masterStore, setMasterStore] = useState('wix');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [syncInfo, setSyncInfo] = useState(null);
  const [isAlertLoading, setIsAlertLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const pageSize = 25;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  // Fetch Data
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/etsy_easy_sync/api/product_mappings?page_size=${pageSize}&page=${currentPage}`
      );
      setData(response.data.data || []);
      setMasterStore(response.data.master_store);
      setTotalPages(response.data.pagination?.total_pages || 1);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      toast({
        title: 'Error',
        description: 'Failed to load product mappings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, toast]);

  // Fetch Synchronization Info
  const fetchLastSyncInfo = useCallback(async () => {
    setIsAlertLoading(true);
    try {
      const { data } = await axiosInstance.get(`/etsy_easy_sync/api/sync_processes?page_size=1`);
      const latestSync = data?.data?.[0];
      if (!latestSync) {
        setSyncInfo('No synchronization history available.');
        return;
      }

      const startedAt = formatToLocalTime(latestSync.started_at);
      const nextScheduledAt = formatToLocalTime(latestSync.next_scheduled_at);
      const completedAt = formatToLocalTime(latestSync.completed_at);
      const messages = {
        in_progress: `Sync started at ${startedAt} and is still in progress.`,
        completed: `Sync completed at ${completedAt}. ${latestSync.synced_products_count} products were synced successfully.`,
        failed: `Sync started at ${startedAt} but failed to complete. Please retry.`,
      };

      setSyncInfo(messages[latestSync.status] || `Last sync started at ${startedAt}. Status ${latestSync.status}, next scheduled at ${nextScheduledAt || 'N/A' }`);
    } catch (error) {
      console.error('Failed to load sync info:', error);
      toast({
        title: 'Error',
        description: 'Failed to load synchronization information.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      setSyncInfo('Failed to load synchronization history.');
    } finally {
      setIsAlertLoading(false);
    }
  }, [toast]);

  // Force Sync
  const fetchProductMappings = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`/etsy_easy_sync/api/product_mappings/fetch`);
      setData(response.data.data || []);
      setTotalPages(response.data.pagination?.total_pages || 1);
      toast({
        title: 'Refresh Successful',
        description: 'Product mappings have been refreshed.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Failed to refresh data:', error);
      toast({
        title: 'Error',
        description: 'Failed to refresh product mappings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const forceSyncProcess = async () => {
    setIsAlertLoading(true);
    try {
      await axiosInstance.post(`/etsy_easy_sync/api/sync_processes/force`);

      toast({
        title: 'Sync Triggered',
        description: 'Products are in process of synchronization at the moment.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      navigate('/etsy_easy_sync/synchronization_history')
    } catch (error) {
      console.error('Failed to refresh data:', error);
      toast({
        title: 'Error',
        description: 'Failed to start product synchronization.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsAlertLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchLastSyncInfo();
  }, [fetchData, fetchLastSyncInfo]);

  // Update Mapping
  const updateMapping = async (id, updates, updatedProductKey, updatedProduct) => {
    try {
      await axiosInstance.put(`/etsy_easy_sync/api/product_mappings/${id}`, updates);
      setData((prev) =>
        prev.map((item) =>
          item.id === id
            ? {
              ...item,
              [updatedProductKey]: updatedProduct || { ...item[updatedProductKey] },
              ...updates,
            }
            : item
        )
      );
      toast({
        title: 'Update Successful',
        description: 'Mapping updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Failed to update mapping:', error);
      toast({
        title: 'Error',
        description: error.response.data?.error || 'Failed to update mapping.',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Delete Mapping
  const deleteMapping = async () => {
    try {
      await axiosInstance.delete(`/etsy_easy_sync/api/product_mappings/${selectedRecord.id}`);
      toast({
        title: 'Deleted Successfully',
        description: 'Product mapping has been deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      fetchData(); // Refresh the data after deletion
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Failed to delete mapping:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete product mapping.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  // Columns Definition
  let columns = [
    {
      header: `Etsy Product ${masterStore === 'etsy' ? '(Master Store)' : ''}`,
      key: 'etsy_product',
      render: (row) =>
        row.etsy_product == null || row.etsy_product.is_editable ? (
          <ProductSelector
            vendor='etsy'
            currentProduct={row.etsy_product}
            searchProducts={(term) =>
              axiosInstance
                .get(`/etsy_easy_sync/api/products/search?platform=etsy&term=${term}`)
                .then((res) => res.data.results)
            }
            onSelect={(product) =>
              updateMapping(
                row.id,
                { etsy_product_id: product.etsy_id },
                'etsy_product',
                { ...product, is_editable: true }
              )
            }
          />
        ) : (
          <ProductCard {...row.etsy_product} />
        ),
    },
    {
      header: `Wix Product ${masterStore === 'wix' ? '(Master Store)' : ''}`,
      key: 'wix_product',
      render: (row) =>
        row.wix_product == null || row.wix_product.is_editable ? (
          <ProductSelector
            vendor='wix'
            currentProduct={row.wix_product}
            searchProducts={(term) =>
              axiosInstance
                .get(`/etsy_easy_sync/api/products/search?platform=wix&term=${term}`)
                .then((res) => res.data.results)
            }
            onSelect={(product) =>
              updateMapping(
                row.id,
                { wix_product_id: product.wix_id },
                'wix_product',
                { ...product, is_editable: true }
              )
            }
          />
        ) : (
          <ProductCard {...row.wix_product} />
        ),
    },
    {
      header: 'Last Sync',
      key: 'last_sync',
      render: (row) => <span>{formatToLocalTime(row.last_sync)}</span>, // Using date-fns for local time
    },
    {
      header: 'Run Sync',
      key: 'sync_enabled',
      render: (row) => (
        <Switch
          size="md"
          colorScheme="blue"
          isChecked={row.sync_enabled}
          onChange={(e) => updateMapping(row.id, { sync_enabled: e.target.checked })}
        />
      ),
    },
    {
      header: 'Action',
      key: 'action',
      render: (row) => (
        <IconButton
          aria-label="Delete"
          icon={<DeleteIcon />}
          colorScheme="red"
          onClick={() => {
            setSelectedRecord(row);
            onOpen();
          }}
        />
      ),
    },
  ];

  // But master store as the first column
  columns = columns.sort((a, b) =>
    a.key === `${masterStore}_product` ? -1 : b.key === `${masterStore}_product` ? 1 : 0
  );

  return (
    <Box px="4" bg={useColorModeValue('gray.100', 'gray.800')}>
      <Flex justify="space-between" align="center" mb="6">
        <Heading size="lg">Product Mappings</Heading>
        <Button
          aria-label="Refresh"
          rightIcon={<RepeatIcon />}
          colorScheme="blue"
          onClick={fetchProductMappings} // Trigger POST refresh
        >Force Products Pull</Button>
      </Flex>

      <Box mb="6">
        <NoticeAlert
          label="Latest Synchronization"
          description={isAlertLoading ? 'Loading...' : syncInfo}
          buttonText="Force Sync"
          onClick={forceSyncProcess}
          isLoading={isAlertLoading}
        />
      </Box>

      <Box bg="white" borderRadius="lg" p="6" boxShadow="md">
        <PaginatedTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </Box>

      {/* Alert Dialog for Deleting Mapping */}
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Product Mapping
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this product mapping? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteMapping} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ProductMappings;
