import React, { useEffect, useState } from 'react';
import { Flex, useColorModeValue, Spinner } from '@chakra-ui/react';
import { ActionCard } from 'shared/organisms/ActionCard';
import { PiPlugsConnectedFill } from 'react-icons/pi';
import axiosInstance from 'utils/axiosInstance';

const ConnectEtsy = () => {
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    setLoading(true);
    try {
      // Fetch the OAuth link from your backend
      const response = await axiosInstance.post('/etsy_easy_sync/api/etsy_oauth');
      const oauthLink = response.data.oauth_link;

      // Redirect user to the OAuth link in the same tab
      window.location.href = oauthLink;
    } catch (err) {
      console.error('Failed to initiate Etsy OAuth:', err);
      setLoading(false);
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="full"
      px="6"
      bg={useColorModeValue('gray.100', 'gray.800')}
    >
      <ActionCard
        icon={PiPlugsConnectedFill}
        title="Let’s connect your Etsy shop"
        description="Connect your Etsy shop to sync products and inventory. Make sure you're logged into the correct Etsy account before proceeding to avoid any setup issues."
        buttonText="Connect My Etsy Account"
        onButtonClick={handleConnect}
        loading={loading}
      />
    </Flex>
  );
};

export default ConnectEtsy;
