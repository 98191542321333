import React from 'react';
import { Box, Heading, Text, useColorModeValue as mode, Link, Flex } from '@chakra-ui/react';
import { Header } from 'shared/organisms/Header';

const Help = () => {
  return (
    <Box>
      {/* Help Content */}
      <Box as="section">
        <Flex justify="center" align="center" mx="auto" py="12" px={{ base: '6', md: '8' }} maxW="7xl">
          <Box textAlign="center" bg={mode('white', 'gray.800')} p="8" rounded="lg" shadow="lg">
            <Heading size="xl" mb="4" color={mode('blue.600', 'blue.400')}>
              Need Assistance?
            </Heading>
            <Text fontSize="lg" mb="4">
              If you're experiencing any issues or need support, please don't hesitate to reach out to us.
            </Text>
            <Text fontSize="lg" fontWeight="bold" mb="4">
              Contact our support team at:
              <Link href="mailto:andrew@rubyroid.tech" color={mode('blue.600', 'blue.400')} fontSize="lg" ml={1}>
                 andrew@rubyroid.tech
              </Link>
            </Text>
            <Text fontSize="md" mt="4" color={mode('gray.600', 'gray.400')}>
              We're here to help you with anything you need!
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Help;
