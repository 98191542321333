import React, { useState, useEffect } from 'react';
import { Box, Spinner, useColorModeValue as mode, Text } from '@chakra-ui/react';
import { Header } from 'shared/organisms/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from 'utils/axiosInstance';

const EtsyEasySyncLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // Pages that are part of the install flow where we don't want to redirect
  const freeVisitPages = ['/etsy_easy_sync/help', '/etsy_easy_sync/payment', '/etsy_easy_sync/connect_etsy/finalize'];

  // Links for the header navigation
  const links = [
    { label: 'Product Mappings', href: '/etsy_easy_sync/product_mappings' },
    { label: 'Synchronization History', href: '/etsy_easy_sync/synchronization_history' },
    { label: 'Billing', href: '/etsy_easy_sync/payment' },
    { label: 'Help', href: '/etsy_easy_sync/help' },
  ];

  // Determine if we are on the /etsy_easy_sync/install page
  const hideLinks = ['/etsy_easy_sync/install', '/etsy_easy_sync/pricing_info'].includes(location.pathname);

  useEffect(() => {
    const fetchFlowState = async () => {
      try {
        const response = await axiosInstance.get('/etsy_easy_sync/api/flow_state');
        const { current_step } = response.data;

        const stepToRouteMap = {
          payment: '/etsy_easy_sync/payment',
          connect_etsy: '/etsy_easy_sync/connect_etsy',
          settings: '/etsy_easy_sync/settings',
          mappings: '/etsy_easy_sync/product_mappings',
        };

        const targetRoute = stepToRouteMap[current_step];
        const freeVisitPage = freeVisitPages.includes(location.pathname);

        // Redirect only if not already on the target route
        if (location.pathname !== targetRoute && !freeVisitPage) { navigate(targetRoute, { replace: true }); }
      } catch (error) {
        console.error('Failed to fetch flow state:', error);
        if (location.pathname === '/' || location.pathname === '/etsy_easy_sync') {
          navigate('/etsy_easy_sync/product_mappings', { replace: true });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchFlowState();
  }, [location.pathname, navigate]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  return (
    <Box bg={mode('gray.100', 'gray.700')}>
      <Header
        homeHref="/etsy_easy_sync/languages"
        logo="/etsy_easy_sync_logo.png"
        links={hideLinks ? [] : links}
        activeLink={location.pathname}
      />
      <Box maxW="7xl" as="main" py="6" px="4" mx="auto" minHeight="calc(100vh - 120px)">
        <Outlet />
      </Box>

      {/* Footer */}
      <Box
        bg={mode('gray.200', 'gray.600')}
        py="4"
        textAlign="center"
        bottom="0"
        width="100%"
        height="55px"
      >
        <Text color="gray.500" fontSize="sm">
          The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
        </Text>
      </Box>
    </Box>
  );
};

export default EtsyEasySyncLayout;
