import React, {useState, useEffect} from 'react'
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Textarea,
  useColorModeValue as mode,
  Heading,
  HStack,
  Text,
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  IconButton,
  Select,
  Input,
  Button,
  InputGroup,
  InputRightElement, Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react'
import {HiArrowLeft} from 'react-icons/hi'
import {SearchIcon} from '@chakra-ui/icons'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import axiosInstance from 'utils/axiosInstance'
import CustomTranslationRow from "../organisms/CustomTranslationRow";
import {v4 as uuidv4} from 'uuid'

const EditTranslation = () => {
  const {languageId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [translations, setTranslations] = useState([]);
  const [pages, setPages] = useState([]);
  const [customTranslations, setCustomTranslations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const queryParams = new URLSearchParams(location.search);
  const [selectedPage, setSelectedPage] = useState(queryParams.get('page_path') || '');
  const [searchQuery, setSearchQuery] = useState(queryParams.get('query') || '');
  const itemsPerPage = 50;

  const updateUrlParams = () => {
    const params = new URLSearchParams();
    if (selectedPage) params.set('page_path', selectedPage);
    if (searchQuery) params.set('query', searchQuery);
    params.set('page', currentPage);
    navigate({search: params.toString()}, {replace: true});
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [translationsResponse, customTranslationsResponse, pagesResponse] = await Promise.all([
        axiosInstance.get(`/weblang/api/languages/${languageId}/page_translations`, {
          params: {page_path: selectedPage, query: searchQuery, page: currentPage, per_page: itemsPerPage},
        }),
        axiosInstance.get(`/weblang/api/languages/${languageId}/element_translations`, {
          params: {page_path: selectedPage},
        }),
        axiosInstance.get(`/weblang/api/languages/${languageId}/page_translations/page_options`),
      ]);

      setTranslations(translationsResponse.data.page_translations);
      setCustomTranslations(customTranslationsResponse.data.element_translations);
      setPages(pagesResponse.data.pages);
      setTotalPages(translationsResponse.data.pagination.total_pages);
    } catch (err) {
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [languageId, selectedPage, currentPage]);

  useEffect(() => {
    updateUrlParams();
  }, [selectedPage, currentPage]);

  const handlePageSelect = (e) => {
    setSelectedPage(e.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  const handleSearchClick = () => {
    setCurrentPage(1); // Reset to the first page
    updateUrlParams();
    fetchData();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleInputChange = (id, value) => {
    setTranslations((prevTranslations) =>
      prevTranslations.map((translation) =>
        translation.id === id ? {...translation, translation: value, edited: true} : translation
      )
    );
  };

  const handleTextTranslationSave = async (id) => {
    const translationToUpdate = translations.find((translation) => translation.id === id);
    try {
      await axiosInstance.put(`/weblang/api/languages/${languageId}/page_translations/${id}`, {
        translation: translationToUpdate.translation,
      });
      setAlertMessage('Translation saved successfully.');
      setAlertStatus('success');
      setTranslations((prevTranslations) =>
        prevTranslations.map((translation) =>
          translation.id === id ? {...translation, edited: false} : translation
        )
      );
    } catch (error) {
      setAlertMessage('Failed to save the translation. Please try again.');
      setAlertStatus('error');
    }
  };

  // Handler for creating a new custom translation row
  const createElementTranslationRow = (type) => {
    const newRow = {
      id: null,
      type: type,
      selector: type === 'custom' ? '' : `img[src^='']`, // Default for images/videos
      action: 'replace',
      target_attribute: type === 'custom' ? '' : 'src',
      replacement_value: '',
      tempKey: uuidv4()
    }
    setCustomTranslations((prev) => [newRow, ...prev])
  }

  const handleCustomElementTranslationSave = async (updatedRow) => {
    try {
      await axiosInstance.post(`/weblang/api/languages/${languageId}/element_translations/upsert`, {
        element_translation: updatedRow,
      })
      setAlertMessage('Translation saved successfully.')
      setAlertStatus('success')
      setCustomTranslations((prev) =>
        prev.map((item) =>
          item.replacement_value === updatedRow.replacement_value ? updatedRow : item
        )
      )
    } catch (error) {
      setAlertMessage('Failed to save the translation. Please try again.')
      setAlertStatus('error')
    }
  }

  const handleCustomElementTranslationDelete = async (deletedRow) => {
    try {
      await axiosInstance.delete(`/weblang/api/languages/${languageId}/element_translations/${deletedRow.id}`)
      setAlertMessage('Translation deleted successfully.')
      setAlertStatus('success')
      setCustomTranslations((prev) => prev.filter((item) => item.id !== deletedRow.id))
    } catch (error) {
      setAlertMessage('Failed to delete the translation. Please try again.')
      setAlertStatus('error')
    }
  }

  return (
    <Box bg={mode('gray.100', 'gray.700')} minHeight="100vh">
      <Box maxW="7xl" mx="auto" px={{base: '6', md: '8'}}>
        <HStack spacing={4}>
          <IconButton
            icon={<HiArrowLeft/>}
            aria-label="Back to Languages"
            onClick={() => navigate('/weblang/languages')}
            mr={1}
          />
          <Heading size="lg">Manage Translations</Heading>
        </HStack>
      </Box>

      <Box as="section" py="6">
        <Box maxW="7xl" mx="auto" px={{base: '6', md: '8'}}>
          {alertMessage && (
            <Alert status={alertStatus} mb="6" borderRadius="md">
              <AlertIcon/>
              {alertMessage}
            </Alert>
          )}

          {loading ? (
            <Box textAlign="center" py="10">
              <Spinner size="xl"/>
              <Text mt="4">Loading Translations...</Text>
            </Box>
          ) : (
            <>
              <Flex mb="4" justify="space-between" align="center" w="100%">
                <Select
                  placeholder="Filter by Page"
                  value={selectedPage}
                  onChange={handlePageSelect}
                  bg="white"
                  maxW="200px"
                  borderRadius="md"
                >
                  {pages.map((page) => (
                    <option key={page} value={page}>
                      {page}
                    </option>
                  ))}
                </Select>
                <InputGroup w="full" ml={4}>
                  <Input
                    placeholder="Search translations"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    bg="white"
                    borderRadius="md"
                  />
                  <InputRightElement>
                    <Button
                      bg="blue.500"
                      color="white"
                      _hover={{bg: "blue.600"}}
                      onClick={handleSearchClick}
                      p={2}
                      borderRadius="md"
                    >
                      <SearchIcon boxSize="1em"/>
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              {/* Custom Element Translations Section */}
              <Flex justify="space-between" align="center" mt="8" mb="4">
                <Heading size="md">Custom Elements Translations</Heading>
                <Box>
                  <Button
                    as="a"
                    href="https://rubyroidtech.tawk.help/article/how-to-translate-image-video"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="sm"
                    bg="gray.50"
                    color="gray.800"
                    mr={2}
                    leftIcon={<Box as="span" className="chakra-icon" aria-hidden="true">?</Box>}
                  >
                    Guide
                  </Button>
                  <Menu>
                    <MenuButton as={Button} colorScheme="blue" size="sm">
                      + Create Translation
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => createElementTranslationRow('image')}>
                        Image Translation
                      </MenuItem>
                      <MenuItem onClick={() => createElementTranslationRow('youtube_video')}>
                        YouTube Video Translation
                      </MenuItem>
                      <MenuItem onClick={() => createElementTranslationRow('custom')}>
                        Custom Translation
                      </MenuItem>
                      {/* Needs additional work to be usable by clients */}
                      {/*<MenuItem onClick={() => createElementTranslationRow('video')}>*/}
                      {/*  Video Translation*/}
                      {/*</MenuItem>*/}
                    </MenuList>
                  </Menu>
                </Box>

              </Flex>
              <TableContainer
                bg="white"
                borderRadius="md"
                boxShadow="sm"
                borderWidth="1px"
                borderColor="gray.200"
                overflow="hidden"
                mb={8}
              >
                <Table variant="simple">
                  <Thead bg={mode('gray.50', 'gray.900')}>
                    <Tr>
                      <Th>Original</Th>
                      <Th>Translation</Th>
                      {/*<Th>Path</Th>*/}
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {customTranslations.length > 0 ? (
                      customTranslations.map((custom, index) => (
                        <Tr key={index} _hover={{bg: mode('gray.100', 'gray.700')}}>
                          <CustomTranslationRow
                            row={custom}
                            pathOptions={pages}
                            onSave={handleCustomElementTranslationSave}
                            onDelete={handleCustomElementTranslationDelete}
                            key={custom.id || custom.tempKey}
                          />
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan="3" textAlign="center" py={6}>
                          <Box>
                            <Text size="md" fontWeight="bold" mb={2}>
                              No custom translations available
                            </Text>
                            <Box>
                              Learn how to translate videos, images, or other elements by following our{' '}
                              <Box
                                as="a"
                                href="https://rubyroidtech.tawk.help/article/how-to-translate-image-video"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="blue.500"
                                fontWeight="bold"
                                textDecoration="underline"
                              >
                                step-by-step guide
                              </Box>
                            </Box>
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>

              <Heading size="md" mt="8" mb="4">Text Elements Translations</Heading>
              <TableContainer
                bg="white"
                borderRadius="md"
                boxShadow="sm"
                borderWidth="1px"
                borderColor="gray.200"
                overflow="hidden"
              >
                <Table variant="simple">
                  <Thead bg={mode('gray.50', 'gray.900')}>
                    <Tr>
                      <Th>Original</Th>
                      <Th>Translation</Th>
                      <Th>Path</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {translations.map((translation) => (
                      <Tr key={translation.id} _hover={{bg: mode('gray.100', 'gray.700')}}>
                        <Td maxWidth="400px" whiteSpace="normal" wordBreak="break-word">
                          {translation.text}
                        </Td>
                        <Td>
                          <Textarea
                            value={translation.translation}
                            onChange={(e) =>
                              handleInputChange(translation.id, e.target.value)
                            }
                            rows={3}
                            bg="white"
                            borderRadius="md"
                          />
                        </Td>
                        <Td>{translation.page_path}</Td>
                        <Td>
                          <Button
                            colorScheme="blue"
                            size="sm"
                            isDisabled={!translation.edited}
                            onClick={() => handleTextTranslationSave(translation.id)}
                            borderRadius="md"
                          >
                            Save
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex mt={4} justify="center">
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  isDisabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Text mx={4} alignSelf="center">
                  Page {currentPage} of {totalPages}
                </Text>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  isDisabled={currentPage === totalPages || totalPages === 0}
                >
                  Next
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditTranslation
