import {
  Box,
  Flex,
  HStack,
  useColorModeValue as mode,
  VisuallyHidden,
  Image,
} from '@chakra-ui/react';
import * as React from 'react';
import { MobileNav } from 'shared/molecules/Header/MobileNav';
import { NavLink } from 'shared/molecules/Header/NavLink';

interface LinkItem {
  label: string;
  href: string;
  icon?: React.ElementType;
}

interface HeaderProps {
  homeHref?: string; // Link for the logo, defaults to "/"
  logo?: string; // Logo URL or path (optional with default)
  links?: LinkItem[]; // List of navigation links
  activeLink?: string; // Current active link
  actionsComponent?: React.ReactNode; // Custom action buttons or dropdowns
}

export const Header: React.FC<HeaderProps> = ({
                                                logo = '/default-logo.png', // Default logo
                                                links = [], // Default to an empty array
                                                activeLink,
                                                actionsComponent,
                                                homeHref = '/', // Default home link
                                              }) => {
  return (
    <Box as="header" bg={mode('white', 'gray.800')} borderBottomWidth="1px">
      <Box maxW="7xl" mx="auto" py="4" px={{ base: '6', md: '8' }}>
        <Flex as="nav" justify="space-between" align="center">
          {/* Logo and Desktop Links */}
          <HStack spacing="8">
            <Box as="a" href={homeHref} rel="home">
              <VisuallyHidden>Home</VisuallyHidden>
              <Image src={logo} alt="App Logo" h="8" />
            </Box>

            {/* Desktop Navigation */}
            <HStack display={{ base: 'none', lg: 'flex' }} spacing="8">
              {links && links.map((link) => (
                <NavLink.Desktop
                  key={link.label}
                  href={link.href}
                  active={activeLink === link.href}
                >
                  {link.label}
                </NavLink.Desktop>
              ))}
            </HStack>
          </HStack>

          {/* Custom Actions or Empty */}
          <Flex align="center">
            <HStack spacing="8" display={{ base: 'none', md: 'flex' }}>
              {actionsComponent}
            </HStack>
            <Box ml="5">
              <MobileNav links={links} />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
