import {
  createIcon,
  Flex,
  HStack,
  Stack,
  Switch,
  Text,
  VisuallyHidden,
  useColorModeValue as mode,
  StackProps,
} from '@chakra-ui/react';
import * as React from 'react';

interface DurationSwitchProps {
  value?: 'monthly' | 'yearly'; // Controlled value
  defaultValue?: 'monthly' | 'yearly'; // Uncontrolled default value
  onChange?: (value: 'monthly' | 'yearly') => void; // Custom change handler
  stackProps?: StackProps; // Props for the parent Stack component
}

export const DurationSwitch: React.FC<DurationSwitchProps> = ({
                                                                    value,
                                                                    defaultValue = 'monthly',
                                                                    onChange,
                                                                    stackProps,
                                                                  }) => {
  const [internalValue, setInternalValue] = React.useState<'monthly' | 'yearly'>(
    defaultValue
  );

  const isControlled = value !== undefined;
  const currentValue = isControlled ? value : internalValue;

  const handleToggle = () => {
    const newValue = currentValue === 'monthly' ? 'yearly' : 'monthly';
    if (!isControlled) {
      setInternalValue(newValue);
    }
    onChange?.(newValue);
  };

  return (
    <Stack align="center" {...stackProps}>
      <VisuallyHidden as="label" htmlFor="duration-switcher">
        Switch price duration
      </VisuallyHidden>
      <HStack spacing="3">
        <Text
          fontWeight="semibold"
          color={currentValue === 'monthly' ? mode('blue.600', 'blue.300') : undefined}
        >
          Monthly
        </Text>
        <Switch
          id="duration-switcher"
          isChecked={currentValue === 'yearly'}
          onChange={handleToggle}
        />
        <Text
          fontWeight="semibold"
          color={currentValue === 'yearly' ? mode('blue.600', 'blue.300') : undefined}
        >
          Yearly
        </Text>
      </HStack>
    </Stack>
  );
};
