import axiosInstance from '../utils/axiosInstance';

const weblangFlowControlMiddleware = async (pathname, navigate) => {
  try {
    const response = await axiosInstance.get('/weblang/api/flow_state');
    const currentStep = response.data?.current_step;

    const stepToUrlMap = {
      install: '/weblang/install',
      payment: '/weblang/payment',
    };

    const redirectUrl = stepToUrlMap[currentStep];

    // Redirect if necessary
    if (redirectUrl && pathname !== redirectUrl) { navigate(redirectUrl); }
  } catch (error) {
    console.error('Failed to fetch Weblang flow state:', error);
  }
};

export default weblangFlowControlMiddleware;
