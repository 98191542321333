import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react';
import { Header } from 'shared/organisms/Header';
import { HiInformationCircle } from 'react-icons/hi';
import axiosInstance from 'utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

const Install = () => {
  // State to manage selected languages and errors
  const [selectedOriginalLanguage, setSelectedOriginalLanguage] = useState('EN');
  const [selectedLanguages, setSelectedLanguages] = useState([]); // Store language objects (code, name)
  const [configId, setConfigId] = useState(null); // Track existing config ID if available
  const [errors, setErrors] = useState({ originalLanguage: false, targetLanguage: false });
  const [availableSourceLanguages, setAvailableSourceLanguages] = useState([]); // For original languages
  const [availableTargetLanguages, setAvailableTargetLanguages] = useState([]); // For target languages
  const [loadingSourceLanguages, setLoadingSourceLanguages] = useState(true);
  const [loadingTargetLanguages, setLoadingTargetLanguages] = useState(true);
  const [apiError, setApiError] = useState(''); // Store API error messages
  const [successMessage, setSuccessMessage] = useState(''); // Store success messages

  const navigate = useNavigate(); // Hook for navigation

  // Fetch source (original) languages from the API
  useEffect(() => {
    const fetchSourceLanguages = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/supported_languages?type=source');
        const languages = response.data;

        // Preselect a language with `preselected: true` if no config
        if (!configId) {
          const preselectedSource = languages.find(lang => lang.preselected);
          if (preselectedSource) setSelectedOriginalLanguage(preselectedSource.code);
        }

        setAvailableSourceLanguages(languages);
        setLoadingSourceLanguages(false);
      } catch (error) {
        console.error('Error fetching source languages:', error);
        setApiError('Failed to fetch original languages.');
      } finally {
        setLoadingSourceLanguages(false);
      }
    };

    fetchSourceLanguages();
  }, [configId]);

  // Fetch target languages from the API and preselect if no config
  useEffect(() => {
    const fetchTargetLanguages = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/supported_languages?type=target');
        const languages = response.data;

        // Preselect languages with `preselected: true` if no config
        if (!configId) {
          const preselectedTargets = languages.filter(lang => lang.preselected);
          setSelectedLanguages(preselectedTargets);
        }

        setAvailableTargetLanguages(languages);
        setLoadingTargetLanguages(false);
      } catch (error) {
        console.error('Error fetching target languages:', error);
        setApiError('Failed to fetch target languages.');
      } finally {
        setLoadingTargetLanguages(false);
      }
    };

    fetchTargetLanguages();
  }, [configId]);

  // Fetch existing config (if any) on load
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/configs');
        const config = response.data.config;
        if (config && availableTargetLanguages.length) {
          setConfigId(config.id);
          setSelectedOriginalLanguage(config.original_language);
          setSelectedLanguages(config.target_languages.map(code => availableTargetLanguages.find(lang => lang.code === code)));
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No existing configuration found. Proceeding to create a new config.');
          setApiError(''); // Reset the error, no need to show the 404 error
        } else {
          console.error('Error fetching configuration:', error);
          setApiError('Failed to fetch configuration.');
        }
      }
    };

    fetchConfig();
  }, [availableTargetLanguages]);

  // Handler to add a new language
  const handleLanguageSelect = (e) => {
    const selectedLanguageCode = e.target.value;
    const selectedLanguage = availableTargetLanguages.find(lang => lang.code === selectedLanguageCode);
    if (selectedLanguage && !selectedLanguages.some(lang => lang.code === selectedLanguage.code)) {
      setSelectedLanguages([...selectedLanguages, selectedLanguage]);
      setErrors(prev => ({ ...prev, targetLanguage: false })); // Clear error if a target language is selected
    }
  };

  // Handler to remove a language from the list
  const handleRemoveLanguage = (languageCode) => {
    setSelectedLanguages(selectedLanguages.filter(lang => lang.code !== languageCode));
  };

  // Handle form submission and check for empty fields
  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    setApiError(''); // Clear previous API error
    setSuccessMessage(''); // Clear previous success message

    if (!selectedOriginalLanguage) {
      setErrors(prev => ({ ...prev, originalLanguage: true }));
      hasError = true;
    }

    if (selectedLanguages.length === 0) {
      setErrors(prev => ({ ...prev, targetLanguage: true }));
      hasError = true;
    }

    if (!hasError) {
      try {
        const configData = {
          original_language: selectedOriginalLanguage,
          target_languages: selectedLanguages.map(lang => lang.code),
        };

        if (configId) {
          // Update existing config
          await axiosInstance.put(`/weblang/api/configs/${configId}`, { config: configData });
        } else {
          // Create new config if no existing config is found (handles 404 cases)
          await axiosInstance.post('/weblang/api/configs', { config: configData });
        }

        // Show success message
        setSuccessMessage('Configuration saved successfully!');

        // Redirect to /weblang/payment after successful config creation/update
        navigate('/weblang/payment');
      } catch (error) {
        console.error('Error submitting the configuration:', error);
        setApiError('Failed to save configuration. Please try again.');
      }
    }
  };

  return (
    <>
      <Box as="section" py="12">
        <Box
          textAlign="center"
          bg={mode('white', 'gray.800')}
          shadow="lg"
          maxW={{ base: 'xl', md: '3xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          py="12"
          rounded="lg"
        >
          <Box maxW="md" mx="auto">
            {/* Show API error */}
            {apiError && (
              <Alert status="error" mb="4">
                <AlertIcon />
                {apiError}
              </Alert>
            )}

            {/* Show success message */}
            {successMessage && (
              <Alert status="success" mb="4">
                <AlertIcon />
                {successMessage}
              </Alert>
            )}

            {/* Green Small Text */}
            <Text
              color={mode('green.600', 'green.400')}
              fontWeight="bold"
              fontSize="sm"
              letterSpacing="wide"
            >
              Make your website global 🌍
            </Text>

            {/* Main Heading */}
            <Heading mt="4" fontWeight="extrabold" fontSize="2xl">
              Go international in three clicks!
            </Heading>

            {/* Form for Language Selection */}
            <Box mt="6">
              <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                  {/* Original Language Selector with Label and Error Handling */}
                  <FormControl isInvalid={errors.originalLanguage}>
                    <FormLabel>Original Language</FormLabel>
                    {loadingSourceLanguages ? (
                      <Spinner size="md" />
                    ) : (
                      <Select
                        placeholder="Select original language"
                        value={selectedOriginalLanguage}
                        onChange={(e) => {
                          setSelectedOriginalLanguage(e.target.value);
                          setErrors(prev => ({ ...prev, originalLanguage: false }));
                        }}
                        isDisabled={loadingSourceLanguages} // Disable while loading
                      >
                        {availableSourceLanguages.map(language => (
                          <option key={language.code} value={language.code}>
                            {language.name}
                          </option>
                        ))}
                      </Select>
                    )}
                    <FormErrorMessage>Original language is required.</FormErrorMessage>
                  </FormControl>

                  {/* Target Language Selector with Label and Error Handling */}
                  <FormControl isInvalid={errors.targetLanguage}>
                    <FormLabel>Target Language</FormLabel>
                    {loadingTargetLanguages ? (
                      <Spinner size="md" />
                    ) : (
                      <Select
                        placeholder="Select target language"
                        onChange={handleLanguageSelect}
                        isDisabled={loadingTargetLanguages} // Disable while loading
                      >
                        {availableTargetLanguages.map(language => (
                          <option key={language.code} value={language.code}>
                            {language.name}
                          </option>
                        ))}
                      </Select>
                    )}
                    <FormErrorMessage>At least one target language is required.</FormErrorMessage>
                  </FormControl>

                  {/* Render selected languages as tags */}
                  <Stack direction="row" spacing={2}>
                    {selectedLanguages.map(language => (
                      <Tag
                        size="md"
                        key={language.code}
                        borderRadius="full"
                        variant="solid"
                        colorScheme="blue"
                      >
                        <TagLabel>{language.name}</TagLabel> {/* Display language name */}
                        <TagCloseButton onClick={() => handleRemoveLanguage(language.code)} />
                      </Tag>
                    ))}
                  </Stack>

                  {/* Translate Now Button */}
                  <Button
                    type="submit"
                    w="full"
                    colorScheme="blue"
                    size="lg"
                    textTransform="uppercase"
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    Translate Now
                  </Button>
                </Stack>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Install;
