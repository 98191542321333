import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  useColorModeValue,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import PaginatedTable from 'shared/organisms/PaginatedTable';
import CreateProductCard from "../molecules/CreateProductCard";
import ProductCard from '../molecules/ProductCard';
import axiosInstance from 'utils/axiosInstance';
import formatToLocalTime from 'utils/timeTools';

const SynchronizationRun = () => {
  const { synchronizationId } = useParams(); // Extract syncProcessId from the URL
  const navigate = useNavigate(); // For back navigation
  const [data, setData] = useState([]); // Table data
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 25;

  // Fetch data for the given sync_process ID
  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/etsy_easy_sync/api/sync_processes/${synchronizationId}/product_sync_processes?page_size=${pageSize}&page=${page}`
      );
      setData(response.data.data || []);
      setTotalPages(response.data.pagination?.total_pages || 1);
    } catch (error) {
      console.error('Failed to fetch product sync processes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Automatically re-fetch data every 3 seconds if in_progress or pending statuses exist
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (data.some((item) => ['in_progress', 'pending'].includes(item.status))) {
        fetchData(currentPage);
      }
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [data, currentPage]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, synchronizationId]);

  // Get status badge
  const getStatusBadge = (status) => {
    const colorScheme = {
      synced: 'green',
      in_progress: 'yellow',
      pending: 'yellow',
      failed: 'red',
    }[status]

    return (
      <Badge colorScheme={colorScheme} rounded="full" px="2" size="sm">
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  // Define table columns
  const columns = [
    {
      header: 'Etsy Product',
      key: 'etsy_product',
      render: (row) => row.etsy_product ? <ProductCard {...row.etsy_product} /> : <CreateProductCard hoverBg='' vendor='etsy' />,
    },
    {
      header: 'Wix Product',
      key: 'wix_product',
      render: (row) => row.wix_product ? <ProductCard {...row.wix_product} /> : <CreateProductCard hoverBg='' vendor='wix' />,
    },
    {
      header: 'Attempted At',
      key: 'attempted_at',
      render: (row) => formatToLocalTime(row.attempted_at),
    },
    {
      header: 'Status',
      key: 'status',
      render: (row) => getStatusBadge(row.status),
    },
  ];

  return (
    <Box px="4" bg={useColorModeValue('gray.100', 'gray.800')} minH="100vh">
      {/* Header */}
      <Flex justify="space-between" align="center" mb="6">
        <Flex align="center">
          <IconButton
            aria-label="Go Back"
            icon={<ArrowBackIcon />}
            variant="ghost"
            size="lg"
            onClick={() => navigate(-1)} // Navigate back
          />
          <Heading as="h1" size="lg" color={useColorModeValue('gray.800', 'white')}>
            Synchronization Run
          </Heading>
        </Flex>
      </Flex>

      {/* Synchronization Run Table */}
      <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" boxShadow="md" p="6">
        {isLoading ? (
          <Flex justify="center" align="center" minH="200px">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <PaginatedTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default SynchronizationRun;
