import React, { useState, useEffect, useRef } from 'react'
import {
  Td,
  VStack,
  HStack,
  Text,
  Input,
  Select,
  Button,
  Image,
  AspectRatio,
  Box,
  Spinner,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'

// Helper to create CSS selectors from URLs
const getCSSSelector = (url, type) => {
  if (type === 'image') return `img[src^='${url}']`
  if (type === 'youtube_video') return `iframe[src^='${url}']`
  return ''
}

// Helper to extract YouTube Video ID from typical patterns
const getYouTubeVideoId = (url) => {
  // Accepts both youtube.com/watch?v=ID and youtu.be/ID or embed/ID
  const match = url.match(/(?:embed\/|v=|youtu\.be\/)([^?&/]+)/)
  return match ? match[1] : null
}

// Helper to get labels based on type
const getLabelsByType = (type) => {
  switch (type) {
    case 'image':
      return { label: 'Image URL', newLabel: 'New Image URL' }
    case 'youtube_video':
      return { label: 'YouTube URL', newLabel: 'New YouTube URL' }
    case 'video':
      return { label: 'Video URL', newLabel: 'New Video URL' }
    default:
      return { label: 'CSS Selector', newLabel: 'Replacement Value' }
  }
}

// Helper to extract URL from CSS selector
const extractURLFromSelector = (selector) => {
  const match = selector?.match(/(?:img|iframe)\[src\^='([^']+)']/)
  return match ? match[1] : ''
}

// Transform YouTube URLs like "https://www.youtube.com/watch?v=xyz" or "https://youtu.be/xyz"
// into "https://www.youtube.com/embed/xyz" for embedding
const transformYouTubeURL = (url) => {
  const videoId = getYouTubeVideoId(url)
  return videoId ? `https://www.youtube.com/embed/${videoId}` : url
}

// Clean up Wixstatic image URLs by truncating after the file extension
// e.g., "https://static.wixstatic.com/media/abc.jpg?v=123" -> "https://static.wixstatic.com/media/abc.jpg"
const cleanWixstaticImageURL = (url) => {
  if (!url.includes('static.wixstatic.com')) return url

  // Common image extensions you might expect
  const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp']
  let cleanedUrl = url

  // For each extension, if found in the URL, truncate everything after it
  for (const ext of extensions) {
    const idx = cleanedUrl.toLowerCase().indexOf(ext)
    if (idx !== -1) {
      // idx + ext.length gives the end of the extension
      cleanedUrl = cleanedUrl.slice(0, idx + ext.length)
      break
    }
  }

  return cleanedUrl
}

// Component to render media previews
const Preview = ({ url, type }) => {
  const previewStyles = {
    width: '120px',
    maxWidth: '120px',
    borderRadius: 'md',
    overflow: 'hidden',
  }

  if (type === 'image' && url) {
    return (
      <AspectRatio ratio={16 / 9} {...previewStyles}>
        <Image src={url} alt="Image preview" objectFit="cover" />
      </AspectRatio>
    )
  }

  if (type === 'youtube_video' && url) {
    const videoId = getYouTubeVideoId(url)
    const thumbnailUrl = videoId
      ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      : null
    return thumbnailUrl ? (
      <AspectRatio ratio={16 / 9} {...previewStyles}>
        <Image src={thumbnailUrl} alt="YouTube thumbnail" objectFit="cover" />
      </AspectRatio>
    ) : (
      <Text fontSize="xs" color="gray.500">
        Invalid YouTube URL
      </Text>
    )
  }

  if (type === 'video' && url) {
    return (
      <AspectRatio ratio={16 / 9} {...previewStyles}>
        <video src={url} controls style={{ objectFit: 'cover' }} />
      </AspectRatio>
    )
  }

  return null
}

const CustomTranslationRow = ({ row, onSave, onDelete }) => {
  const [localData, setLocalData] = useState({
    ...row,
    original_value: extractURLFromSelector(row.selector), // Derive from selector
  })
  const [isSaving, setIsSaving] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  // AlertDialog state for delete confirmation
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const cancelRef = useRef(null)

  // Update the original value when selector changes
  useEffect(() => {
    if (row.selector) {
      const derivedURL = extractURLFromSelector(row.selector)
      setLocalData((prev) => ({ ...prev, original_value: derivedURL }))
    }
  }, [row.selector])

  // Track changes to localData to enable/disable Save button
  useEffect(() => {
    // Check if localData is different from the initial row
    const hasChanges = JSON.stringify(localData) !== JSON.stringify({
      ...row,
      original_value: extractURLFromSelector(row.selector),
    })
    setIsChanged(hasChanges)
  }, [localData, row])

  const handleChange = (field, value) => {
    setLocalData((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'selector' && {
        original_value: extractURLFromSelector(value),
      }),
    }))
  }

  const handleDeleteClick = () => {
    setIsDeleteOpen(true)
  }

  const handleConfirmDelete = () => {
    setIsDeleteOpen(false)
    onDelete(row) // Notify parent with original row (or localData if you prefer)
  }

  const handleCloseDialog = () => {
    setIsDeleteOpen(false)
  }

  const transformDataBeforeSave = (data) => {
    let transformedData = { ...data }

    // YouTube videos: transform to embed form
    if (transformedData.type === 'youtube_video') {
      // For 'original_value' & 'replacement_value', transform if present
      if (transformedData.original_value) {
        transformedData.original_value = transformYouTubeURL(transformedData.original_value)
      }
      if (transformedData.replacement_value) {
        transformedData.replacement_value = transformYouTubeURL(transformedData.replacement_value)
      }
    }

    // 2. Images: If from static.wixstatic.com, cut off extra query params
    if (transformedData.type === 'image') {
      if (transformedData.original_value) {
        transformedData.original_value = cleanWixstaticImageURL(transformedData.original_value)
      }
      if (transformedData.replacement_value) {
        transformedData.replacement_value = cleanWixstaticImageURL(transformedData.replacement_value)
      }
    }

    return transformedData
  }

  const handleSave = () => {
    setIsSaving(true)

    const transformedLocalData = transformDataBeforeSave(localData)
    const processedData = {
      ...transformedLocalData,
      selector:
        transformedLocalData.type === 'custom'
          ? transformedLocalData.selector
          : getCSSSelector(transformedLocalData.original_value, transformedLocalData.type),
    }

    onSave(processedData)
    setLocalData((prev) => ({
      ...prev,
      original_value: transformedLocalData.original_value,
      replacement_value: transformedLocalData.replacement_value,
    }))
    setIsSaving(false)
  }

  // Render custom type inputs
  const renderCustomInputs = () => (
    <>
      {/* Left Column: CSS Selector Input */}
      <Td maxWidth="250px" whiteSpace="normal">
        <VStack align="stretch" spacing={2}>
          <Text fontSize="sm" fontWeight="bold">CSS Selector</Text>
          <Input
            placeholder="Enter CSS Selector"
            value={localData.selector || ''}
            onChange={(e) => handleChange('selector', e.target.value)}
            bg="white"
            size="sm"
          />
        </VStack>
      </Td>

      {/* Right Column: Action, Target Attribute, Replacement Value */}
      <Td>
        <Box p={3} border="1px" borderColor="gray.200" borderRadius="md" bg="gray.50">
          <HStack spacing={3} mb={2}>
            <Select
              placeholder="Action"
              value={localData.action || ''}
              onChange={(e) => handleChange('action', e.target.value)}
              size="sm"
              flex="1"
              bg="white"
            >
              <option value="replace">Replace</option>
              <option value="append">Append</option>
              <option value="remove">Remove</option>
            </Select>
            <Input
              placeholder="Target Attribute"
              value={localData.target_attribute || ''}
              onChange={(e) => handleChange('target_attribute', e.target.value)}
              size="sm"
              flex="1"
              bg="white"
            />
          </HStack>
          <Input
            placeholder="Replacement Value"
            value={localData.replacement_value || ''}
            onChange={(e) => handleChange('replacement_value', e.target.value)}
            bg="white"
            size="sm"
            width="100%"
          />
        </Box>
      </Td>
    </>
  )

  return (
    <>
      {localData.type === 'custom' ? (
        renderCustomInputs()
      ) : (
        <>
          {/* Left Column: Original URL and Preview */}
          <Td maxWidth="250px" whiteSpace="normal">
            <VStack align="stretch" spacing={2}>
              <Text fontSize="sm" fontWeight="bold">{getLabelsByType(localData.type).label}</Text>
              <Preview url={localData.original_value} type={localData.type} />
              <Input
                placeholder={getLabelsByType(localData.type).label}
                value={localData.original_value || ''}
                onChange={(e) => handleChange('original_value', e.target.value)}
                bg="white"
                size="sm"
              />
            </VStack>
          </Td>

          {/* Right Column: Replacement Input and Preview */}
          <Td>
            <VStack spacing={2} align="stretch">
              <Text fontSize="sm" fontWeight="bold">{getLabelsByType(localData.type).newLabel}</Text>
              <Preview url={localData.replacement_value} type={localData.type} />
              <Input
                placeholder={getLabelsByType(localData.type).newLabel}
                value={localData.replacement_value || ''}
                onChange={(e) => handleChange('replacement_value', e.target.value)}
                bg="white"
                size="sm"
              />
            </VStack>
          </Td>
        </>
      )}

      {/* Path Column: Select path - Commented since current UI can confuse customer */}
      {/*<Td>*/}
      {/*  <VStack align="stretch" spacing={2}>*/}
      {/*    {pathOptions === null ? (*/}
      {/*      // Show spinner while pathOptions are null (loading)*/}
      {/*      <Box textAlign="center">*/}
      {/*        <Spinner size="sm" color="blue.500" />*/}
      {/*      </Box>*/}
      {/*    ) : (*/}
      {/*      // Render the dropdown with page options*/}
      {/*      <Select*/}
      {/*        placeholder="All pages"*/}
      {/*        value={localData.page_path || ''}*/}
      {/*        onChange={(e) => handleChange('page_path', e.target.value)}*/}
      {/*        bg="white"*/}
      {/*        size="sm"*/}
      {/*      >*/}
      {/*        /!* Ensure selected value is part of options *!/*/}
      {/*        {!pathOptions.includes(localData.page_path) && localData.page_path && (*/}
      {/*          <option value={localData.page_path}>*/}
      {/*            {localData.page_path}*/}
      {/*          </option>*/}
      {/*        )}*/}
      {/*        {pathOptions.map((path) => (*/}
      {/*          <option key={path} value={path}>*/}
      {/*            {path}*/}
      {/*          </option>*/}
      {/*        ))}*/}
      {/*      </Select>*/}
      {/*    )}*/}
      {/*  </VStack>*/}
      {/*</Td>*/}

      {/* Actions Column */}
      <Td>
        <HStack spacing={2}>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={handleSave}
            isLoading={isSaving}
            isDisabled={!isChanged}
            loadingText="Saving..."
          >
            Save
          </Button>
          <Button
            colorScheme="red"
            variant="outline"
            size="sm"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </HStack>

        {/* AlertDialog for Confirming Delete */}
        <AlertDialog
          isOpen={isDeleteOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleCloseDialog}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Translation
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this translation?
                This action cannot be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCloseDialog}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Td>
    </>
  )
}

export default CustomTranslationRow
